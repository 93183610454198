import PaymentsLayout from "./components/PaymentsLayout/PaymentsLayout";
import { OnBoardingProvider } from "./context/OnBoardingContext";
import "./App.css";
import { useEffect } from "react";

function App() {
  useEffect(() => {
    const resize = () => {
      const vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty("--vh", `${vh}px`);
    };

    window.addEventListener("resize", resize);
    window.addEventListener("load", resize);

    return () => {
      window.removeEventListener("resize", resize);
      window.removeEventListener("load", resize);
    };
  }, []);

  return (
    <>
      <div className="background-wrapper">
        <OnBoardingProvider>
          <PaymentsLayout />
        </OnBoardingProvider>
      </div>
    </>
  );
}

export default App;
