import React from "react";
import "./LoadingScreen.css";
import Footer from "../Footer/Footer";

const LoadingScreen = ({ title = "Payment Options" }) => {
  return (
    <div className="loadingScreen">
      <div className="statusHOC">
        <div className="statusHOC__header">
          <h1 style={{ fontFamily: "Montserrat, sans-serif" }}>{title}</h1>
        </div>
        <div className="statusHOC__mid">
          <span className="loader"></span>
          <p style={{ fontSize: "1rem" }}>
            Unlocking the Future of Finance <br />
            Decentro’s Mighty APIs at Work!
          </p>
        </div>
        <div className="statusHOC__footer">
          <Footer />
        </div>
      </div>
    </div>
  );
};

export default LoadingScreen;
