import { useEffect, useState } from "react";
import axios from "axios";
import { APIConfig } from "../services/apiConfiguration";
// services imports
import { randomUUID } from "../services/randomUUID";

const CancelToken = axios.CancelToken;
const source = CancelToken.source();

const useSignUpValidation = (
  initialState,
  validate,
  accessToken,
  toOnBoardingCard,
  setAuthConfig,
  // newly added
  paymentLinkApiRequest,
  kycApiRequest,
  dataPushApiRequest
) => {
  const [values, setValues] = useState(initialState);
  const [errors, setErrors] = useState({});
  const [touched, setTouched] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  // Toast state
  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState("");

  const handleChange = (event) => {
    const { name, value, type, checked } = event.target;

    if (type === "checkbox") {
      setValues({
        ...values,
        [name]: checked,
      });
    } else {
      setValues({
        ...values,
        [name]: value,
      });
    }

    setErrors((prevState) => ({
      ...prevState,
      [name]: touched[name] ? validate({ ...values, [name]: value })[name] : "",
    }));
  };

  const handleBlur = (event) => {
    const { name } = event.target;
    const validationErrors = validate(values);
    setErrors((prevState) => ({
      ...prevState,
      [name]: validationErrors[name] || "",
    }));
    setTouched((prevState) => ({
      ...prevState,
      [name]: true,
    }));
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const validationErrors = validate(values);
    setErrors(validationErrors);

    if (isSubmitting && Object.keys(validationErrors).length === 0) {
      signUpSubmit();
    }
  };

  // & sign up submit API hit
  const signUpSubmit = () => {
    // api from context
    // & payment link api hit
    paymentLinkApiRequest(values);
    // & KYC link api hit - hyperstream
    kycApiRequest(values);
    // & Data push detail api hit - data push to doc
    dataPushApiRequest(values);

    setIsLoading(true);
    // setShowToast(false);
  };

  useEffect(() => {
    const validationErrors = validate(values);

    if (Object.keys(validationErrors).length === 0) {
      setIsSubmitting(true);
    } else {
      setIsSubmitting(false);
    }
  }, [values, validate]);

  return {
    values,
    errors,
    handleChange,
    handleSubmit,
    handleBlur,
    isLoading,
    isSubmitting,
    showToast,
    setShowToast,
    toastMessage,
  };
};

export default useSignUpValidation;
