import React, { useContext } from "react";
import "./RefundFailedScreen.css";
import StatusHOC from "../StatusHOC/StatusHOC";
import OnBoardingContext from "../../context/OnBoardingContext";

const RefundFailedScreen = () => {
  const { toHomeScreen, payoutApiRequest } = useContext(OnBoardingContext);

  const backToRefundScreen = () => {
    payoutApiRequest();
  };

  return (
    <div className="payment-refund-wrapper">
      <StatusHOC
        headerText={"Refund Status"}
        title={"Refund Failed"}
        subTitle={"This payment link has expired or failed."}
        imgUrl={
          "https://decentro-sdk-assets.s3.ap-south-1.amazonaws.com/Failed-Expired-logo.svg"
        }
        showBtn={true}
        btnText={"Back to home"}
        btnFnc={toHomeScreen}
        showBtn2={true}
        btnText2={"Re-initiate Refund"}
        btnFnc2={backToRefundScreen}
      />
    </div>
  );
};

export default RefundFailedScreen;
