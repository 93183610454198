import Footer from "../Footer/Footer";
import { useContext } from "react";
import OnBoardingContext from "../../context/OnBoardingContext";
// styles import
import "./SuccessScreen.css";
import StatusHOC from "../StatusHOC/StatusHOC";

const SuccessScreen = ({
  result = "success",
  screenTitle = "Payment Refund",
  messageResponse = "Refund Successful",
}) => {
  // ^ context
  const { toHomeScreen } = useContext(OnBoardingContext);

  return (
    <div className="payment-refund-wrapper">
      <StatusHOC
        headerText={"Refund Status"}
        title={"Refund Successful"}
        subTitle={"The refund has been successfully completed"}
        imgUrl={
          "https://decentro-sdk-assets.s3.ap-south-1.amazonaws.com/success-logo.svg"
        }
        showBtn={true}
        btnText={"Back to home"}
        btnFnc={toHomeScreen}
      />
    </div>
  );
};

export default SuccessScreen;
