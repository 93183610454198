import React from "react";
import "./Footer.css";

const Footer = () => {
  return (
    <div className="footer">
      <img
        src={
          "https://decentro-icons.s3.ap-south-1.amazonaws.com/POWERED_BY_GFF.svg"
        }
        alt="PoweredByLogo"
      />
    </div>
  );
};

export default Footer;
