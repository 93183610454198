import React, { useContext } from "react";
import OnBoardingContext from "../../context/OnBoardingContext";
import CustomerSignup from "../CustomerSignup/CustomerSignup";
import PaymentLinks from "../PaymentLinks/PaymentLinks";
import PaymentRefund from "../PaymentRefund/PaymentRefund";
// import LoadingScreen from "../UI/LoadingScreen/LoadingScreen";
import LoadingScreen from "../LoadingScreen/LoadingScreen.jsx";
import PendingScreen from "../PendingScreen/PendingScreen.jsx";
import FailedStatusScreen from "../FailedStatusScreen/FailedStatusScreen.jsx";

// styles import
import "./PaymentsLayout.css";
import HomeScreen from "../HomeScreen/HomeScreen";
import SuccessScreen from "../SuccessScreen/SuccessScreen";
import RefundFailedScreen from "../RefundFailedScreen/RefundFailedScreen.jsx";

const PaymentsLayout = () => {
  // ^ context
  const { cardType, kycApiRequest } = useContext(OnBoardingContext);

  // * Card View Variables Enum
  const ONBOARDING_CARD = {
    homeScreen: <HomeScreen />,
    signUp: <CustomerSignup />,
    paymentLinks: <PaymentLinks />,
    loadingScreen: <LoadingScreen />,
    paymentRefund: <PaymentRefund />,
    successScreen: <SuccessScreen />,
    pendingScreen: <PendingScreen />,
    failedScreen: <FailedStatusScreen />,
    refundFailedScreen: <RefundFailedScreen />,
    loadingRefundScreen: <LoadingScreen title="Payments Refund" />,
  };

  return (
    <>
      <div className="main-container">{ONBOARDING_CARD[cardType]}</div>
      {/* <button onClick={kycApiRequest}> Click me</button> */}
    </>
  );
};

export default PaymentsLayout;

// {
//   "decentroTxnId": "FD7F8C9670D345278FA2D9DCB7B7756A",
//   "status": "SUCCESS",
//   "responseCode": "S00000",
//   "message": "Payment Link created Successfully.",
//   "data": {
//       "pspUri": {
//           "gpayUri": "gpay://upi/pay?ver=01&mode=15&am=5.00&cu=INR&pa=decentro.pay@timecosmos&pn=Decentro%20Tech%20Pvt%20Ltd&mc=6012&tr=C3ZID6TJ13HUYMCYKK396206845&tn=Welcome%20to%20Decentro&mid=DECENTRO001&msid=DECENTRO001-001&mtid=DECENTRO001-001",
//           "phonepeUri": "phonepe://pay?ver=01&mode=15&am=5.00&cu=INR&pa=decentro.pay@timecosmos&pn=Decentro%20Tech%20Pvt%20Ltd&mc=6012&tr=C3ZID6TJ13HUYMCYKK396206845&tn=Welcome%20to%20Decentro&mid=DECENTRO001&msid=DECENTRO001-001&mtid=DECENTRO001-001",
//           "paytmUri": "paytmmp://pay?ver=01&mode=15&am=5.00&cu=INR&pa=decentro.pay@timecosmos&pn=Decentro%20Tech%20Pvt%20Ltd&mc=6012&tr=C3ZID6TJ13HUYMCYKK396206845&tn=Welcome%20to%20Decentro&mid=DECENTRO001&msid=DECENTRO001-001&mtid=DECENTRO001-001"
//       },
//       "generatedLink": "https://decf.in/pay/bii992syt3",
//       "transactionId": "FD7F8C9670D345278FA2D9DCB7B7756A",
//       "transactionStatus": "PENDING"
//   },
//   "responseKey": "success_payment_link_created"
// }
