import React, { useContext, useState } from "react";
import OnBoardingContext from "../../context/OnBoardingContext";
import "./PaymentLinks.css";
import Footer from "../Footer/Footer";
import useResponsive from "../../hooks/useResponsive";

const PaymentLinks = () => {
  let dummyResponse = {
    decentroTxnId: "FD7F8C9670D345278FA2D9DCB7B7756A",
    status: "SUCCESS",
    responseCode: "S00000",
    message: "Payment Link created Successfully.",
    data: {
      pspUri: {
        gpayUri:
          "gpay://upi/pay?ver=01&mode=15&am=5.00&cu=INR&pa=decentro.pay@timecosmos&pn=Decentro%20Tech%20Pvt%20Ltd&mc=6012&tr=C3ZID6TJ13HUYMCYKK396206845&tn=Welcome%20to%20Decentro&mid=DECENTRO001&msid=DECENTRO001-001&mtid=DECENTRO001-001",
        phonepeUri:
          "phonepe://pay?ver=01&mode=15&am=5.00&cu=INR&pa=decentro.pay@timecosmos&pn=Decentro%20Tech%20Pvt%20Ltd&mc=6012&tr=C3ZID6TJ13HUYMCYKK396206845&tn=Welcome%20to%20Decentro&mid=DECENTRO001&msid=DECENTRO001-001&mtid=DECENTRO001-001",
        paytmUri:
          "paytmmp://pay?ver=01&mode=15&am=5.00&cu=INR&pa=decentro.pay@timecosmos&pn=Decentro%20Tech%20Pvt%20Ltd&mc=6012&tr=C3ZID6TJ13HUYMCYKK396206845&tn=Welcome%20to%20Decentro&mid=DECENTRO001&msid=DECENTRO001-001&mtid=DECENTRO001-001",
      },
      generatedLink: "https://decf.in/pay/bii992syt3",
      transactionId: "FD7F8C9670D345278FA2D9DCB7B7756A",
      transactionStatus: "PENDING",
    },
    responseKey: "success_payment_link_created",
  };

  let { isMobile } = useResponsive();

  const [selectedCard, setSelectedCard] = useState("");

  // ^ context
  const { paymentLinksDetails, toPendingScreen, isSafari } =
    useContext(OnBoardingContext);

  //   To be used fr css chnages
  // let { pspUri, generatedLink } = dummyResponse.data;
  // to be used when actual production api is hitted
  let { pspUri, generatedLink } = paymentLinksDetails.data;

  //&   state's
  const [linkDetail, setLinkDetail] = useState("");

  //&   handler's
  const cardSelectHandler = (link) => {
    setLinkDetail(link);
    setSelectedCard(link);
  };

  const confirmAndPayHandler = () => {
    toPendingScreen();
    window.open(linkDetail);
  };

  return (
    <div className="payments-card-wrapper">
      {/* <!-- **************** Header **************** --> */}
      <div className="payments-card-wrapper__header">
        <h1 style={{ fontFamily: "Montserrat, sans-serif" }}>Payment</h1>
      </div>

      <div className="payments-card-wrapper__mid">
        {/* <!-- **************** Amount Card **************** --> */}
        <div className="balance-card">
          <div className="balance-container">
            <p className="amount-label">Amount</p>
            <div className="amount-value">
              <span className="currency-entity">&#8377;</span>6
            </div>
          </div>
        </div>

        {/* <!-- **************** PAYMENT - METHODS  **************** --> */}
        <div className="payment-methods-container">
          <div className="payment-methods-container__scroll">
            <p className="payment-methods-title">Select Payment Method</p>

            {/* <!-- ****************  APP CARDS ****************  --> */}
            {/* <!-- PHONEPE CARD CONTAINER --> */}
            <div
              className={`payment-wrapper ${
                selectedCard === pspUri.phonepeUri ? "selected" : ""
              }`}
            >
              <label
                htmlFor="phonepe"
                onClick={() => cardSelectHandler(pspUri.phonepeUri)}
              >
                {/* <input
                  type="radio"
                  id="phonepe"
                  name="paymentApp"
                  value="phonepe"
                  onClick={() => cardSelectHandler(pspUri.phonepeUri)}
                /> */}
                <div className="payment-context">
                  <div className="payment-card">
                    <div className="payment-option-container">
                      <div className="option-logo">
                        <img
                          src="https://decentro-icons.s3.ap-south-1.amazonaws.com/PhonePe.svg"
                          alt=""
                        />
                      </div>
                      <div className="option-title">PhonePe</div>
                      {/* <img
                        src={
                          "https://decentro-sdk-assets.s3.ap-south-1.amazonaws.com/arrow-right.svg"
                        }
                        alt="right arrow"
                      /> */}
                    </div>
                  </div>
                </div>
              </label>
            </div>

            {/* <!-- GOOGLEPAY CARD CONTAINER --> */}

            <div
              className={`payment-wrapper ${
                selectedCard === pspUri.gpayUri ? "selected" : ""
              }`}
            >
              <label
                htmlFor="googlepay"
                onClick={() => cardSelectHandler(pspUri.gpayUri)}
              >
                {/* <input
                  type="radio"
                  id="googlepay"
                  name="paymentApp"
                  value="googlepay"
                  onClick={() => cardSelectHandler(pspUri.gpayUri)}
                /> */}
                <div className="payment-context">
                  <div className="payment-card">
                    <div className="payment-option-container">
                      <div className="option-logo">
                        <img
                          src="https://decentro-icons.s3.ap-south-1.amazonaws.com/GooglePay.svg"
                          alt=""
                        />
                      </div>
                      <div className="option-title">Google Pay</div>
                      {/* <img
                        src={
                          "https://decentro-sdk-assets.s3.ap-south-1.amazonaws.com/arrow-right.svg"
                        }
                        alt="right arrow"
                      /> */}
                    </div>
                  </div>
                </div>
              </label>
            </div>

            {/* <!-- PAYTM CARD CONTAINER --> */}
            <div
              className={`payment-wrapper ${
                selectedCard === pspUri.paytmUri ? "selected" : ""
              }`}
            >
              <label
                htmlFor="paytm"
                onClick={() => cardSelectHandler(pspUri.paytmUri)}
              >
                {/* <input
                  type="radio"
                  id="paytm"
                  name="paymentApp"
                  value="paytm"
                  onClick={() => cardSelectHandler(pspUri.paytmUri)}
                /> */}
                <div className="payment-context">
                  <div className="payment-card">
                    <div className="payment-option-container">
                      <div className="option-logo">
                        <img
                          src="https://decentro-icons.s3.ap-south-1.amazonaws.com/PayTM.svg"
                          alt=""
                        />
                      </div>
                      <div className="option-title">PayTM</div>
                      {/* <img
                        src={
                          "https://decentro-sdk-assets.s3.ap-south-1.amazonaws.com/arrow-right.svg"
                        }
                        alt="right arrow"
                      /> */}
                    </div>
                  </div>
                </div>
              </label>
            </div>

            {/* <!-- OTHER UPI CARD CONTAINER --> */}

            {!isSafari && (
              <div
                className={`payment-wrapper ${
                  selectedCard === generatedLink ? "selected" : ""
                }`}
              >
                <label
                  htmlFor="other-upi"
                  onClick={() => cardSelectHandler(generatedLink)}
                >
                  {/* <input
                  type="radio"
                  id="other-upi"
                  name="paymentApp"
                  value="other-upi"
                  onClick={() => cardSelectHandler(generatedLink)}
                /> */}
                  <div className="payment-context">
                    <div className="payment-card">
                      <div className="payment-option-container">
                        <div className="option-logo">
                          <img
                            src="https://decentro-sdk-assets.s3.ap-south-1.amazonaws.com/otherUPI.svg"
                            alt=""
                          />
                        </div>
                        <div className="option-title">Other UPI app</div>
                        {/* <img
                        src={
                          "https://decentro-sdk-assets.s3.ap-south-1.amazonaws.com/arrow-right.svg"
                        }
                        alt="right arrow"
                      /> */}
                      </div>
                    </div>
                  </div>
                </label>
              </div>
            )}
          </div>

          {/* <!-- ****************  FOOTER ****************  --> */}
          <div className="card-footer">
            <button
              // className="submit btn-disabled"
              className={`submit ${linkDetail.length === 0 && "btn-disabled"}`}
              type="submit"
              onClick={confirmAndPayHandler}
            >
              Confirm & Pay
            </button>
            {!isMobile && (
              <div>
                <img
                  src="https://decentro-icons.s3.ap-south-1.amazonaws.com/footer-logo.svg"
                  alt=""
                />
              </div>
            )}
          </div>
        </div>
      </div>

      {isMobile && (
        <div className="signup__footer">
          <Footer />
        </div>
      )}
    </div>
  );
};

export default PaymentLinks;
