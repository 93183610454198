import React, { useState, useContext, useEffect } from "react";
import OnBoardingContext from "../../context/OnBoardingContext";
import CustomLoader from "../Loader/CustomLoader.jsx";

// styles import
import "./PaymentRefund.css";
import Footer from "../Footer/Footer";

const PaymentRefund = () => {
  // ^ context
  const { hyperstreamKYCResponse, payoutApiRequest } =
    useContext(OnBoardingContext);

  const dummyLoadApprovalAmount = [10000, 12000, 15000, 20000, 22000, 30000];

  const [loaderTime, setLoaderTime] = useState(true);

  useEffect(() => {
    let temp = setInterval(() => {
      setLoaderTime(false);
    }, 2000);

    return () => {
      clearInterval(temp);
    };
  }, []);

  // console.log(
  //   "🚀 ~ file: PaymentRefund.js:12 ~ PaymentRefund ~ hyperstreamKYCResponse:",
  //   hyperstreamKYCResponse.data.CKYC_SEARCH_AND_DOWNLOAD.personal_details
  // );

  let renderDetails;

  if (hyperstreamKYCResponse.length === 0) {
    renderDetails = (
      <div className="payment-refund-wrapper__mid-top">
        <div className="statusHOC__mid payment-refund-wrapper__mid-top-img">
          <img
            src={
              "https://decentro-sdk-assets.s3.ap-south-1.amazonaws.com/success-logo.svg"
            }
            alt=""
          />
          <h1 style={{ fontFamily: "Montserrat, sans-serif" }}>
            Payment Successful
          </h1>
        </div>
      </div>
    );
  } else {
    renderDetails = (
      <div className="hyperstream-container">
        <p className="hyperstream-title">
          Decentro’s Hyperstreams API got your KYC details verified and found
          the best credit offer
        </p>

        <div className="hyperstream-details">
          <p className="kyc-label-title">Full Name</p>
          {hyperstreamKYCResponse?.data?.CKYC_SEARCH_AND_DOWNLOAD
            ?.personal_details?.first_name && (
            <p className="kyc-detail">
              {
                hyperstreamKYCResponse.data.CKYC_SEARCH_AND_DOWNLOAD
                  .personal_details.first_name
              }
            </p>
          )}
        </div>

        {hyperstreamKYCResponse?.data?.CKYC_SEARCH_AND_DOWNLOAD
          ?.personal_details?.pan && (
          <div className="hyperstream-details">
            <p className="kyc-label-title">PAN</p>
            <p className="kyc-detail">
              {
                hyperstreamKYCResponse.data.CKYC_SEARCH_AND_DOWNLOAD
                  .personal_details.pan
              }
            </p>
          </div>
        )}

        {hyperstreamKYCResponse?.data?.CKYC_SEARCH_AND_DOWNLOAD
          ?.personal_details?.dob && (
          <div className="hyperstream-details">
            <p className="kyc-label-title">Date of Birth</p>
            <p className="kyc-detail">
              {
                hyperstreamKYCResponse.data.CKYC_SEARCH_AND_DOWNLOAD
                  .personal_details.dob
              }
            </p>
          </div>
        )}

        {hyperstreamKYCResponse?.data?.CKYC_SEARCH_AND_DOWNLOAD
          ?.personal_details?.permanent_address && (
          <div className="hyperstream-details">
            <p className="kyc-label-title">Address</p>
            <p className="kyc-detail">
              {
                hyperstreamKYCResponse.data.CKYC_SEARCH_AND_DOWNLOAD
                  .personal_details.permanent_address
              }
            </p>
          </div>
        )}
      </div>
    );
  }

  return (
    <div className="payment-refund-wrapper">
      <div className="payment-refund-wrapper__header">
        <h1 style={{ fontFamily: "Montserrat, sans-serif" }}>
          Available Offer
        </h1>
      </div>

      <div className="payment-refund-wrapper__mid">
        {renderDetails}
        {/* Success image */}

        <div className="payment-refund-wrapper__mid-bottom">
          <div className="payment-refund-wrapper__mid-bottom-container">
            <div className="offer-container">
              <p className="offer__title">You have one offer available</p>
              <div className="offer__card">
                <div className="offer__card-left">
                  <p>ABC Finance Ltd.</p>

                  <div style={{ display: "flex", gap: "1rem" }}>
                    <div className="offer__card-leftL">
                      <p className="blue_title">Rate of Interest</p>
                      <span>2% p.m.</span>
                    </div>
                    <div className="offer__card-leftR">
                      <p className="blue_title">Tenure</p>
                      <span>12 months</span>
                    </div>
                  </div>
                </div>
                <div className="offer__card-right">
                  <p className="blue_title">Loan Amount</p>
                  <h1 style={{ textAlign: "end" }}>20K</h1>
                </div>
              </div>
            </div>
            {/* Refund btn */}
            <div className="btn__box">
              <button
                onClick={payoutApiRequest}
                className={`refund-theme-btn`}
                disabled={loaderTime}
                type="submit"
                style={{ width: "100%" }}
              >
                Initiate Refund
                {loaderTime && <CustomLoader />}
              </button>
            </div>
          </div>
        </div>
      </div>

      <div className="payment-refund-wrapper__footer">
        <div className="signup__footer" style={{ textAlign: "center" }}>
          <Footer />
        </div>
      </div>
    </div>
  );
};

export default PaymentRefund;
