//  context imports
import React, { createContext, useState, useEffect } from "react";
import axios from "axios";
import { randomUUID } from "../services/randomUUID";

const OnBoardingContext = createContext();

export const OnBoardingProvider = ({ children }) => {
  // * Type of card state
  const [cardType, setCardType] = useState("homeScreen");
  // Types of Card (Screen's) Available
  // homeScreen
  // signUp
  // loadingScreen
  // paymentLinks
  // paymentRefund - success link for payment screen with init refund btn
  // successScreen
  // pendingScreen
  // failedScreen
  // loadingRefundScreen
  // refundFailedScreen

  // * safari browser or not

  const [isSafari, setIsSafari] = useState(false);

  useEffect(() => {
    const userAgent = window.navigator.userAgent;
    setIsSafari(userAgent.includes("Safari") && !userAgent.includes("Chrome"));

    // You can add additional checks for Safari versions or specific behaviors if needed.
  }, []);

  // * For Access Token
  const [accessToken, setAccessToken] = useState("");

  // * For authConfig params
  const [authConfig, setAuthConfig] = useState({});

  // * For Customer SignIp response
  const [customerDetails, setCustomerDetails] = useState("");

  // * For API response's
  const [paymentLinksDetails, setPaymentLinksDetails] = useState("");
  const [transactionIDPaymentsLink, setTransactionIDPaymentsLink] =
    useState("");
  // * KYC response hyperstream
  const [hyperstreamKYCResponse, setHyperstreamKYCResponse] = useState("");
  // * UPI id from API response
  const [upiIdResponse, setUpiIdResponse] = useState("");

  // & Change to SignUp
  const toSignUpCard = () => {
    setCardType("signUp");
  };

  // & Change to paymentLinks
  const toPaymentLinks = () => {
    setCardType("paymentLinks");
  };

  // & Change to loadingScreen
  const toLoadingScreen = () => {
    setCardType("loadingScreen");
  };

  // & Change to refundLoadingScreen
  const toRefundLoadingScreen = () => {
    setCardType("loadingRefundScreen");
  };

  // & Change to paymentRefund
  const toPaymentRefund = () => {
    setCardType("paymentRefund");
  };

  // & Change to HomeScreen
  const toHomeScreen = () => {
    setCardType("homeScreen");
  };

  // & Change to SuccessScreen
  const toSuccessScreen = () => {
    setCardType("successScreen");
  };

  // & Change to PendingPaymentLinkScreen
  const toPendingScreen = () => {
    setCardType("pendingScreen");
  };

  // & Change to FailedScreen
  const toFailedScreen = () => {
    setCardType("failedScreen");
  };

  // re-initiate refund screen
  const toRefundFailedScreen = () => {
    setCardType("refundFailedScreen");
  };

  // loadingRefundScreen

  // *  Data Push Api Request -- added to keep logs
  const dataPushApiRequest = (values) => {
    // * loading screen
    // toLoadingScreen();

    // & Api Call
    // const headers = {
    //   client_id: process.env.REACT_APP_CLIENT_ID,
    //   client_secret: process.env.REACT_APP_CLIENT_SECRET,
    // };

    const payloadData = {
      name: values.firstName,
      phone: values.mobileNumber,
      email: values.email,
    };

    axios
      .post(
        // process.env.REACT_APP_PROD_BASEURL + "/frontend/v2/payments/upi/link",
        "https://staging.workflow.decentro.tech/webhook/contact_details",
        payloadData,
        {
          // headers: headers,
        }
      )
      .then((response) => {})
      .catch((error) => {
        console.error("There was an error!", error);
      });
  };

  // *  Payment link Api Request
  const paymentLinkApiRequest = (values) => {
    // * setting signUp info
    setCustomerDetails(values);

    // * loading screen
    toLoadingScreen();

    // & Api Call
    const headers = {
      client_id: process.env.REACT_APP_LINKS_CLIENT_ID,
      client_secret: process.env.REACT_APP_LINKS_CLIENT_SECRET,
      module_secret: process.env.REACT_APP_COLLECTION_LINKS_MODULE_SECRET,
      provider_secret: process.env.REACT_APP_COLLECTION_LINKS_PROVIDER_SECRET,
    };

    const data = {
      reference_id: randomUUID(),
      payee_account: process.env.REACT_APP_COLLECTION_MODULE_VA,
      amount: 6,
      purpose_message: "Welcome to Decentro",
      generate_qr: 0,
      expiry_time: 20,
      customized_qr_with_logo: 0,
      generate_uri: 1,
    };

    axios
      .post(
        process.env.REACT_APP_PROD_BASEURL + "/frontend/v2/payments/upi/link",
        data,
        {
          headers: headers,
        }
      )
      .then((response) => {
        // ! gts api so commented for performance -- needed
        onSuccessOfPayment(response.data.data);
        setPaymentLinksDetails(response.data);
        // getUpiPaymentApiRequest(response.data.data.transactionId);
        // ^ switch to payment links screen
        toPaymentLinks();
      })
      .catch((error) => {
        console.error("There was an error!", error);
      });
  };

  // *  Payment Validation Api Request
  const confirmPaymentRequest = (transId) => {
    //* saving transId for later use
    setTransactionIDPaymentsLink(transId);

    // & Api Call
    const headers = {
      client_id: process.env.REACT_APP_LINKS_CLIENT_ID,
      client_secret: process.env.REACT_APP_LINKS_CLIENT_SECRET,
      module_secret: process.env.REACT_APP_COLLECTION_LINKS_MODULE_SECRET,
      provider_secret: process.env.REACT_APP_COLLECTION_LINKS_PROVIDER_SECRET,
    };

    axios
      .get(
        process.env.REACT_APP_PROD_BASEURL +
          `/frontend/v2/payments/transaction/${transId}/status`,
        {
          headers: headers,
        }
      )
      .then((response) => {
        console.log(
          "🚀 ~ file: OnBoardingContext.js:207 ~ .then ~ response:",
          response.data
        );

        if (
          response.data.status === "SUCCESS" &&
          response.data.data.transactionStatus === "SUCCESS"
        ) {
          clearInterval(intervalId);
          getUpiPaymentApiRequest(transId);
          // ^ go to refund payment
          toPaymentRefund();
          // old code
          // loginApiCall();
        } else if (
          response.data.status === "SUCCESS" &&
          response.data.data.transactionStatus === "FAILED"
        ) {
          console.log("FAILED in payment link status");
          clearInterval(intervalId);
          getUpiPaymentApiRequest(transId);
          toFailedScreen();
        }
      })
      .catch((error) => {
        console.error("There was an error!", error);
      });
  };

  let intervalId;

  const onSuccessOfPayment = (responseDetail) => {
    // ^ repeating calls to get success on scan results
    intervalId = window.setInterval(function () {
      confirmPaymentRequest(responseDetail.transactionId);
    }, 3500);

    window.setInterval(function () {
      clearInterval(intervalId);
    }, 20000000);
  };

  // *  KYC Hyperstream Api Request
  const kycApiRequest = (values) => {
    // & Api Call
    const headers = {
      client_id: process.env.REACT_APP_CLIENT_ID,
      client_secret: process.env.REACT_APP_CLIENT_SECRET,
      module_secret: "QsZNEjkPMbwKOoE193lABWAFnUioHbNA",
    };

    const data = {
      reference_id: randomUUID(),
      consent: true,
      consent_purpose: "gff demo purpose gff demo purpose",
      hyperstream: "CKYC_PREFILL",
      initial_input: {
        full_name: values.firstName,
        mobile_number: values.mobileNumber,
        // full_name: "Joel Rego",
        // mobile_number: "7406050077",
      },
    };

    axios
      .post(
        process.env.REACT_APP_PROD_BASEURL +
          "/synchronous/hyperstream-executor",
        data,
        {
          headers: headers,
        }
      )
      .then((response) => {
        setHyperstreamKYCResponse(response?.data || "");
      })
      .catch((error) => {
        console.error("There was an error!", error);
      });
  };

  //   * Get Payment UPI ID from Api Request
  const getUpiPaymentApiRequest = (transactionId) => {
    // & Api Call
    const headers = {
      client_id: process.env.REACT_APP_CLIENT_ID,
      client_secret: process.env.REACT_APP_CLIENT_SECRET,
    };

    const data = {
      decentro_company_urn: transactionId,
    };

    axios
      .post(
        process.env.REACT_APP_PROD_BASEURL +
          "/decentro/read/fetch/payment_link/payer_upi",
        data,
        {
          headers: headers,
        }
      )
      .then((response) => {
        setUpiIdResponse(response.data[0]?.upi_id);
      })
      .catch((error) => {
        console.error("There was an error!", error);
      });
  };

  //   *  Payout Api Request - Initiate Refund
  const payoutApiRequest = () => {
    //^ To Loading screen
    toRefundLoadingScreen();

    // & Api Call
    const headers = {
      client_id: process.env.REACT_APP_CLIENT_ID,
      client_secret: process.env.REACT_APP_CLIENT_SECRET,
      module_secret: process.env.REACT_APP_PAYOUTS_MODULE_SECRET,
      provider_secret: process.env.REACT_APP_PAYOUTS_PROVIDER_SECRET,
    };

    const data = {
      reference_id: randomUUID(),
      purpose_message: "gff demo purpose",
      from_account: process.env.REACT_APP_PAYOUTS_MODULE_VA,
      to_upi: upiIdResponse,
      transfer_type: "UPI",
      transfer_amount: "6",
      beneficiary_details: {
        payee_name: customerDetails.firstName,
      },
    };

    axios
      .post(
        process.env.REACT_APP_PROD_BASEURL +
          "/frontend/core_banking/money_transfer/initiate",
        data,
        {
          headers: headers,
        }
      )
      .then((response) => {
        //^ To Refund Screen
        toSuccessScreen();
      })
      .catch((error) => {
        toRefundFailedScreen();
        console.error("There was an error!", error);
      });
  };

  // ^^^^^^^^^^^^^^^^^^^^^^ KYC ^^^^^^^^^^^^^^^^^^^^^^^^^^^^
  //   *  KYC 1st Api Request - Initiate Refund
  const initiateKYCApiRequest = () => {
    // & Api Call
    const headers = {
      client_id: process.env.REACT_APP_CLIENT_ID,
      client_secret: process.env.REACT_APP_CLIENT_SECRET,
      module_secret: process.env.REACT_APP_KYC_MODULE_SECRET,
    };

    const data = {
      reference_id: randomUUID(),
      consent: true,
      consent_purpose: "for GFF purpose only",
      redirect_url: "https://experience.decentro.tech",
      redirect_to_signup: true,
    };

    axios
      .post(
        process.env.REACT_APP_PROD_BASEURL +
          "/frontend/v2/kyc/digilocker/initiate_session",
        data,
        {
          headers: headers,
        }
      )
      .then((response) => {})
      .catch((error) => {
        console.error("There was an error!", error);
      });
  };

  return (
    <OnBoardingContext.Provider
      value={{
        cardType,
        toSignUpCard,
        toPaymentLinks,
        toLoadingScreen,
        toRefundLoadingScreen,
        toPaymentRefund,
        toSuccessScreen,
        toHomeScreen,
        accessToken,
        setAccessToken,
        authConfig,
        setAuthConfig,
        // newly added
        paymentLinkApiRequest,
        dataPushApiRequest,
        paymentLinksDetails,
        transactionIDPaymentsLink,
        kycApiRequest,
        upiIdResponse,
        payoutApiRequest,
        setHyperstreamKYCResponse,
        hyperstreamKYCResponse,
        toPendingScreen,
        isSafari,
        setIsSafari,
        initiateKYCApiRequest,
      }}
    >
      {children}
    </OnBoardingContext.Provider>
  );
};

export default OnBoardingContext;
