import React, { useContext } from "react";
import "./FailedStatusScreen.css";
import StatusHOC from "../StatusHOC/StatusHOC";
import OnBoardingContext from "../../context/OnBoardingContext";

const FailedStatusScreen = () => {
  const { toHomeScreen } = useContext(OnBoardingContext);

  return (
    <div>
      <StatusHOC
        headerText={"Payment Status"}
        title={"Payment Failed"}
        subTitle={"This payment link has expired or failed."}
        imgUrl={
          "https://decentro-sdk-assets.s3.ap-south-1.amazonaws.com/Failed-Expired-logo.svg"
        }
        footerMessage={
          "Please connect with the merchant/ sender of this link to share a new one with you."
        }
        showBtn={true}
        btnText={"Back to home"}
        btnFnc={toHomeScreen}
      />
    </div>
  );
};

export default FailedStatusScreen;
