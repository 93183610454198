import React, { useContext, useEffect } from "react";
import OnBoardingContext from "../../context/OnBoardingContext";
// hooks imports
import useSignUpValidation from "../../hooks/useSignUpValidation";
// components imports
import Toast from "../UI/Toast";
// styles imports
import "./CustomerSignup.css";
import Footer from "../Footer/Footer.jsx";
import useResponsive from "../../hooks/useResponsive";

const CustomerSignup = () => {
  const INITIAL_STATE = {
    mobileNumber: "",
    email: "",
    firstName: "",
    // amountNumber: "",
    // lastName: "",
    checkbox: "",
  };

  // is mpbile
  let { isMobile } = useResponsive();

  // ^ context
  const {
    paymentLinkApiRequest,
    kycApiRequest,
    dataPushApiRequest,
    accessToken,
    toOnBoardingCard,
    setAuthConfig,
    initiateKYCApiRequest,
  } = useContext(OnBoardingContext);

  const validateForm = (values) => {
    let errors = {};

    // Validation rules
    const mobileRegex = /^[6-9]\d{9}$/;

    if (!values.mobileNumber) {
      errors.mobileNumber = "Please enter your mobile number";
    } else if (!mobileRegex.test(values.mobileNumber)) {
      errors.mobileNumber = "Please enter a valid mobile number";
    }

    const emailRegex =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    if (!values.email) {
      errors.email = "Please enter your email address";
    } else if (!emailRegex.test(values.email)) {
      errors.email = "Please enter a valid email address";
    }

    // const amountRegex = /^\d+$/;
    // if (!values.amountNumber) {
    //   errors.amountNumber = "Please enter your amount";
    // } else if (!amountRegex.test(values.amountNumber)) {
    //   errors.amountNumber = "Please enter a valid amount";
    // }

    const firstName = /^[a-zA-Z ]{5,}$/;
    if (!values.firstName) {
      errors.firstName = "Please enter your full name";
    } else if (!firstName.test(values.firstName)) {
      errors.firstName = "Please enter a valid full name";
    }

    // const lastName = /^[a-zA-Z]+$/;
    // if (!values.lastName) {
    //   errors.lastName = "Please enter your last name";
    // } else if (!lastName.test(values.lastName)) {
    //   errors.lastName = "Please enter a valid last name";
    // }

    if (!values.checkbox) {
      errors.checkbox = "Consent to be checked";
    }

    return errors;
  };

  const {
    values,
    errors,
    handleChange,
    handleBlur,
    handleSubmit,
    isLoading,
    showToast,
    toastMessage,
    isSubmitting,
  } = useSignUpValidation(
    INITIAL_STATE,
    validateForm,
    accessToken,
    toOnBoardingCard,
    setAuthConfig,
    // newly added
    paymentLinkApiRequest,
    kycApiRequest,
    dataPushApiRequest
  );

  useEffect(() => {
    initiateKYCApiRequest();
  }, []);

  return (
    <div className="customerSignup">
      <div className="signup__header">
        <div className="sign-up-heading">Your Details</div>
      </div>

      <div className="mid__section">
        <form
          className="form-wrapper"
          autoComplete="off"
          onSubmit={handleSubmit}
        >
          <div className="form__top">
            {!isMobile && (
              <div className="sign-up-heading">
                <p>Welcome to Decentro</p>
              </div>
            )}

            {isMobile && (
              <div className="sign-up-subheading">
                <p>
                  Fill details to help our powerful APIs to fetch your KYC data
                  in real-time
                </p>
              </div>
            )}
          </div>
          {/* <div className="field-wrapper">
          <label>Amount</label>
          <input
          autoComplete="false"
          type="text"
          maxLength="4"
          name="amountNumber"
          value={values.amountNumber}
          onChange={handleChange}
          onBlur={handleBlur}
          />
          {errors.amountNumber && (
            <p className="error-message">{errors.amountNumber}</p>
            )}
          </div> */}

          <div className="form__mid">
            <div className="field-wrapper">
              <label>Full Name</label>
              <input
                autoComplete="false"
                type="text"
                name="firstName"
                value={values.firstName}
                onChange={handleChange}
                onBlur={handleBlur}
                className="customerSignup__input"
              />
              {errors.firstName && (
                <p className="error-message">{errors.firstName}</p>
              )}
            </div>

            <div className="field-wrapper">
              <label>Mobile Number</label>
              <input
                autoComplete="false"
                type="text"
                maxLength="10"
                name="mobileNumber"
                inputMode="numeric"
                value={values.mobileNumber}
                onChange={handleChange}
                onBlur={handleBlur}
                className="customerSignup__input"
              />
              {errors.mobileNumber && (
                <p className="error-message">{errors.mobileNumber}</p>
              )}
            </div>

            <div className="field-wrapper">
              <label>Email</label>
              <input
                autoComplete="false"
                type="email"
                name="email"
                value={values.email}
                onChange={handleChange}
                onBlur={handleBlur}
                className="customerSignup__input"
              />
              {errors.email && <p className="error-message">{errors.email}</p>}
            </div>
          </div>

          {/* <div className="field-wrapper">
          <label>Last Name</label>
          <input
          autoComplete="false"
          type="text"
          name="lastName"
          value={values.lastName}
          onChange={handleChange}
          onBlur={handleBlur}
          />
          {errors.lastName && (
            <p className="error-message">{errors.lastName}</p>
            )}
          </div> */}

          <div className="form__bottom">
            <div className="terms-container">
              <div className="checkbox-block">
                <input
                  type="checkbox"
                  name="checkbox"
                  checked={values.checkbox}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  className="tandC__checkbox"
                />
              </div>
              <p className="terms-text">
                You agree to Decentro's
                <a
                  href="https://experience.decentro.tech/terms"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  terms and conditions.
                </a>
              </p>
            </div>
            <div className="consent-error-message">
              {errors.checkbox && <p>{errors.checkbox}</p>}
            </div>

            {/* Toast component */}
            {showToast && (
              <Toast message={toastMessage || "Retry, Something went wrong!"} />
            )}

            <button
              className={isSubmitting ? `theme-btn` : `theme-btn__disabled`}
              type="submit"
            >
              {!!isLoading ? <div className="custom-loader"></div> : "Proceed"}
            </button>
          </div>
        </form>
      </div>

      <div className="signup__footer">
        <Footer />
      </div>
    </div>
  );
};

export default CustomerSignup;
