import React, { useContext } from "react";
import "./HomeScreen.css";
import DecLogo from "../../assets/Decentro.svg";
import payLogo from "../../assets/Payments.svg";
import DigiLockerLogo from "../../assets/DigiLocker.svg";
import OnBoardingContext from "../../context/OnBoardingContext";

const HomeScreen = () => {
  // ^ context
  const { toSignUpCard } = useContext(OnBoardingContext);

  let cardDetails = [
    // {
    //   title: "Explore",
    //   subTitle: "Payments, KYC & Lending",
    //   clickText: "toSignUpCard",
    //   icon: payLogo,
    // },
    // {
    //   title: "Dive",
    //   subTitle: "Into DigiLocker Capabilities",
    //   icon: DigiLockerLogo,
    // },
  ];

  return (
    <div className="homeScreen">
      <div className="homeScreen__header">
        <p>WELCOME TO</p>
        <img src={DecLogo} alt="" />
        <p>
          <span>GFF</span> DEMO 2024
        </p>
      </div>

      <div className="container">
        <div className="wrapper">
          <h1 className="wrapper__heading">Let us help you</h1>
          <ul className="sessions">
            <li>
              <p className="wrapper__title">Innovate</p>
            </li>
            <li>
              <p className="wrapper__title">Integrate</p>
            </li>
            <li>
              <p className="wrapper__title">Inspire</p>
            </li>
          </ul>
        </div>
      </div>

      <div className="homeScreen__footer">
        <p>Let’s get started. Pick your adventure!</p>

        <div onClick={toSignUpCard} className="homeScreen__footer-card">
          <div className="homeScreen__footer-card-left">
            <img src={payLogo} alt="" />
          </div>
          <div className="homeScreen__footer-card-mid">
            <p>Explore</p>
            <span>Payments, KYC & Lending</span>
          </div>
          <div className="homeScreen__footer-card-right">
            <img
              src={
                "https://decentro-sdk-assets.s3.ap-south-1.amazonaws.com/arrow-right.svg"
              }
              alt="right arrow"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default HomeScreen;
