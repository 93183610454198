import React from "react";
import "./PendingScreen.css";
import StatusHOC from "../StatusHOC/StatusHOC";

const PendingScreen = () => {
  return (
    <div className="pendingScreen">
      <StatusHOC
        headerText={"Payment Status"}
        title={"Payment Pending"}
        // imgUrl={
        //   "https://decentro-sdk-assets.s3.ap-south-1.amazonaws.com/Pending.svg"
        // }
        subTitle={"Awaiting payment. Please don't refresh the screen."}
      />
    </div>
  );
};

export default PendingScreen;
