import React from "react";
import "./StatusHOC.css";
import Footer from "../Footer/Footer";

const StatusHOC = ({
  headerText,
  title,
  imgUrl,
  subTitle,
  footerMessage,
  showBtn = false,
  btnText,
  btnFnc,
  showBtn2 = false,
  btnText2,
  btnFnc2,
}) => {
  return (
    <div className="statusHOC">
      <div className="statusHOC__header">
        <h1 style={{ fontFamily: "Montserrat, sans-serif" }}>{headerText}</h1>
      </div>
      <div className="statusHOC__mid">
        {imgUrl ? (
          <img src={imgUrl} alt="" />
        ) : (
          <div className="statusHOC__glassHour">
            <span class="loader__glassHour"></span>
          </div>
        )}
        <h1 style={{ fontFamily: "Montserrat, sans-serif" }}>{title}</h1>
        {subTitle && (
          <>
            <p style={{ fontFamily: "Montserrat, sans-serif" }}>{subTitle}</p>
            {footerMessage && (
              <p
                style={{ fontFamily: "Montserrat, sans-serif", width: "100%" }}
              >
                {footerMessage}
              </p>
            )}
          </>
        )}
      </div>
      {showBtn && (
        <button
          style={{ width: "90%" }}
          onClick={btnFnc}
          className={`refund-theme-btn failed__btn`}
          type="submit"
        >
          {btnText}
        </button>
      )}
      {showBtn2 && (
        <button
          style={{ width: "90%" }}
          onClick={btnFnc2}
          className={`refund-theme-btn failed__btn second__btn`}
          type="submit"
        >
          {btnText2}
        </button>
      )}
      <div className="statusHOC__footer">
        {" "}
        <Footer />
      </div>
    </div>
  );
};

export default StatusHOC;
